import React, {FC} from 'react';
import { useTranslation } from 'react-i18next';
import { Navigation } from '../../common/Components/Navigation';
// import AppTemplate from '../../common/Components/Templates/AppTemplate';

const App: FC<INavigationProps> = ({tree}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>{t('homeText')}</div>
      <Navigation tree={tree} />
    </>
  )
}

export default App;