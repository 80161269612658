import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const prepareData = (tree, t, useStaticPaths) => Object.values(tree).map(({path, children, staticPaths, componentName}) => ({
  title: t(componentName),
  path,
  children,
  useStaticPaths,
  ...(useStaticPaths && {staticPaths}),
}));

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const LevelTwoWrapper = styled.ul`
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;
  border-left: solid 1px rgba(0,0,0,.1);
`;

const LevelTwoItemWrapper = styled.li`
  padding: 4px 8px;
`;

const LevelTwoA = styled(Link)`
  color: ${({theme}): string => theme.palette.primary};
  text-decoration: none;
`;


const LevelTwoItem: FC<IProps> = ({title, path, children, staticPaths, useStaticPaths}) => {
  return <LevelTwoItemWrapper>
    <LevelTwoA to={path}>{title}</LevelTwoA>
    {/*{children && <LevelTwo tree={children} useStaticPaths={useStaticPaths}/>}*/}
  </LevelTwoItemWrapper>;
};

const LevelTwo: FC<INavigationProps> = ({tree, useStaticPaths}) => {
  const { t } = useTranslation();
  const res = prepareData(tree, t, useStaticPaths);
  return <LevelTwoWrapper>
    {res.map((props, index) => <LevelTwoItem key={`${index}`} {...props} />)}
  </LevelTwoWrapper>
}

const LevelOneWrapper = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: solid 1px rgba(0,0,0,.1);
  box-shadow: 0 4px 5px rgba(66,80,97,0.1);
`;

const LinkWrapper = styled.div`
  border-bottom: solid 1px rgba(0,0,0,.1);
`;

const StyledA = styled(Link)`
  color: ${({theme}): string => theme.palette.primary};
  font-weight: bold;
  text-decoration: none;
`;

interface IProps extends Omit<INavigationNode, 'componentName'> {
  title: string;
  useStaticPaths: boolean;
}

const LevelOneItem: FC<IProps> = ({title, path, children, staticPaths, useStaticPaths}) => {
  return <LevelOneWrapper>
    <LinkWrapper>
      <StyledA to={path}>{title}</StyledA>
    </LinkWrapper>
    {children && <LevelTwo tree={children} useStaticPaths={useStaticPaths}/>}
  </LevelOneWrapper>;
};



const Navigation: FC<INavigationProps> = ({tree, useStaticPaths}) => {
  const { t } = useTranslation();
  const res = prepareData(tree, t, useStaticPaths);

  return <Wrapper>{res.map((props, index) => <LevelOneItem key={`${index}`} {...props} />)}</Wrapper>;
}

Navigation.defaultProps = {
  useStaticPaths: false,
}

export { Navigation } ;