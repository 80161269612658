import React, { FC } from 'react';
import { Navigation } from '../../../common/Components/Navigation';

const App: FC<INavigationProps> = ({tree}) => {
  return (
    <>
      <div>Navigation for converters</div>
      <Navigation tree={tree} />
    </>
  )
}

export default App;