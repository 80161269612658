import React, { FC } from 'react';
import { Navigation } from "../../../common/Components/Navigation";
// import AppTemplate from '../../../common/Components/Templates/AppTemplate';

const App: FC<INavigationProps> = ({tree}) => {
  return (
    <>
      <div>Navigation for generators</div>
      <Navigation tree={tree} />
    </>

  )
}

export default App;