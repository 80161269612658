import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('HashGenerators')}</title>
      </Helmet>
      <Outlet />
    </>
  )
};

export default App;